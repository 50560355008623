import React, { FC, useEffect, useState, useContext } from 'react';
import Cart from '@/assets/icons/cart.svg';
import { AnimatePresence, motion, useScroll } from 'framer-motion';
import Close from '@/assets/icons/close.svg';
import { StoreContext } from '@/context/storeContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, QuantityControls } from '@mikafi/ui';
import VariantSelector from '@/components/VariantSelector';
import { GROUND } from '../../constants';

interface Props {
  product: any;
  variant: any;
  setVariant: (variant: any) => void;
}

const AddToCart: FC<Props> = ({ product, variant, setVariant }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
  } = product;
  const intl = useIntl();
  const { addVariantToCart, loading, client } = useContext(StoreContext);

  const [ground, setGround] = useState<string>(
    intl.formatMessage({ id: GROUND[0] }),
  );
  const [hidden, setHidden] = useState<boolean>(true);
  const { scrollY } = useScroll();
  const [selectionOpen, setSelectionOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const productVariant =
    // @ts-ignore
    client.product.helpers.variantForOptions(product, variant) || variant;

  useEffect(() => {
    const unsubscribeScroll = scrollY.onChange(update);

    return () => unsubscribeScroll();
  }, []);

  const update = (position: number) => {
    if (
      position < window.innerHeight / 7 ||
      position >= document.body.clientHeight - window.innerHeight - 100
    ) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  };

  const addToCart = async () => {
    if (selectionOpen) {
      await addVariantToCart(productVariant.storefrontId, quantity, [
        {
          key: intl.formatMessage({ id: 'ground' }),
          value: ground,
        },
      ]);
      setVariant({ ...initialVariant });
      setSelectionOpen(false);
      setQuantity(1);
      setGround(intl.formatMessage({ id: GROUND[0] }));
    } else {
      setSelectionOpen(true);
    }
  };

  useEffect(() => {
    if (selectionOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [selectionOpen]);

  const price = new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: priceRangeV2.minVariantPrice.currencyCode,
  }).format(
    selectionOpen
      ? variant.price * quantity
      : priceRangeV2.minVariantPrice.amount,
  );

  return (
    <AnimatePresence>
      {selectionOpen && (
        <div
          className="z-10 fixed inset-0 bg-black opacity-40 opa"
          onClick={() => setSelectionOpen(false)}
        />
      )}

      <motion.div
        key="parent"
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 240 },
        }}
        initial="hidden"
        animate={hidden ? 'hidden' : 'visible'}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        className="z-20 sm:w-80 left-0 sm:left-auto fixed bottom-4 right-0 mx-6 md:mx-8 lg:mx-16"
      >
        <div>
          {selectionOpen && (
            <motion.div
              key="child"
              className="p-5 pb-24 md:pb-20 flex flex-col bg-white rounded-4xl -mb-14"
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 1, y: 250 },
                exit: { opacity: 0, y: 0 },
              }}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
            >
              <div className="flex flex-row items-center justify-between mb-3">
                <div className="text-lg">
                  <FormattedMessage id="selectOptions" />
                </div>
                <Close
                  className="stroke-black w-10 cursor-pointer"
                  onClick={() => setSelectionOpen(false)}
                />
              </div>
              <div className="mb-3">
                <p className="text-xs my-2">
                  <FormattedMessage id="ground" />
                </p>
                <Select
                  name="ground"
                  onChange={e => setGround(e.target.value)}
                  options={GROUND.map(item => ({
                    name: intl.formatMessage({ id: item }),
                    value: intl.formatMessage({ id: item }),
                  }))}
                  value={ground}
                />
              </div>
              <VariantSelector
                variants={variants}
                variant={variant}
                setVariant={setVariant}
                options={options}
              />
              <div className="px-12">
                <QuantityControls
                  handleOnChange={value => setQuantity(value)}
                  value={quantity}
                />
              </div>
            </motion.div>
          )}

          <div className="relative">
            <button
              disabled={loading}
              className="bg-black rounded-full text-white py-5 md:py-4 px-4 flex justify-center items-center flex-row w-full md:w-full"
              onClick={addToCart}
            >
              <Cart className="fill-white mr-2" />

              <div className="flex flex-col items-start">
                <span className="text-base">
                  {loading
                    ? `${intl.formatMessage({ id: 'adding' })}...`
                    : selectionOpen
                    ? intl.formatMessage({ id: 'addToCart' })
                    : intl.formatMessage({ id: 'buy' })}
                </span>

                <span className="text-xs opacity-60">
                  {selectionOpen ? '' : intl.formatMessage({ id: 'from' })}{' '}
                  {price}
                </span>
              </div>
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddToCart;

import React, { FC, ReactNode, useRef } from 'react';
import { useIntl } from 'react-intl';
import { VimeoVideo, VimeoVideoHandle, HoverBubble } from '@mikafi/ui';

interface Props {
  previewVideoId: number;
  videoId?: number;
  videoThumbnail?: ReactNode;
}

const Intro: FC<Props> = ({ previewVideoId, videoId, videoThumbnail }) => {
  const intl = useIntl();
  const videoRef = useRef<VimeoVideoHandle>(null!);

  return (
    <div>
      {/* Video Preview */}
      <div className="h-[70vh] md:h-[90vh] relative">
        {videoId && previewVideoId && (
          <HoverBubble cursorText={intl.formatMessage({ id: 'discoverStory' })}>
            <VimeoVideo
              ref={videoRef}
              previewVideoId={previewVideoId}
              videoId={videoId}
              videoClassName="rounded-b-4xl overflow-fix"
              thumbnail={videoThumbnail}
            />
          </HoverBubble>
        )}

        {!videoId && previewVideoId && (
          <VimeoVideo
            ref={videoRef}
            previewVideoId={previewVideoId}
            videoId={videoId}
            videoClassName="rounded-b-4xl overflow-fix"
            thumbnail={videoThumbnail}
          />
        )}
      </div>
    </div>
  );
};

export default Intro;

import React, { FC, useRef } from 'react';

import { useIntl } from 'react-intl';
import Link from '@/components/Link';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, VimeoVideo, VimeoVideoHandle, HoverBubble } from '@mikafi/ui';

interface Props {}

const AboutMikafi: FC<Props> = () => {
  const intl = useIntl();
  const videoRef = useRef<VimeoVideoHandle>(null!);

  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="md:w-4/12 md:h-[70vh] md:order-2 md:ml-4 flex flex-col flex-0">
        <div className="md:order-3">
          <StaticImage
            src="./../../assets/images/create_own.png"
            alt="Contact"
            objectFit="cover"
            className="w-full aspect-[12/8] md:w-auto md:h-[32rem] md:aspect-auto rounded-4xl overflow-hidden"
            imgClassName="rounded-4xl overflow-hidden"
          />
          <div>
            <div className="text-xl sm:text-2xl font-normal pb-4 mt-6">
              {intl.formatMessage({ id: 'designYourOwnRoast' })}?
            </div>
            <Link to="mailto:hello@mikafi.com">
              <Button variant="transparent">
                {intl.formatMessage({ id: 'contactUs' })}
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 mt-8 md:mt-0">
        {/* Mikafi Story */}
        <div className="relative w-full md:h-[32rem] aspect-[12/8] md:aspect-auto">
          <HoverBubble cursorText={intl.formatMessage({ id: 'discover' })}>
            <VimeoVideo
              ref={videoRef}
              previewVideoId={703239642}
              videoId={703239642}
              thumbnail={
                <StaticImage
                  src="./../../assets/images/bag.jpg"
                  alt="Contact"
                  objectFit="cover"
                  className="h-full w-full"
                />
              }
              videoClassName="rounded-4xl overflow-fix"
            />
          </HoverBubble>
        </div>
        <div>
          <div className="text-xl sm:text-2xl font-normal pb-4 mt-6">
            {intl.formatMessage({ id: 'discoverMikafisWorld' })}
          </div>
          <Button variant="transparent" onClick={() => videoRef.current.play()}>
            {intl.formatMessage({ id: 'watchVideo' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AboutMikafi;

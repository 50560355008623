import { RefObject, useLayoutEffect, useState } from 'react';

function useRefScrollProgress(
  inputRef: RefObject<HTMLDivElement>,
  customStart = 0 as number,
  customEnd = 100 as number,
  before = 0 as number,
  after = 0 as number,
) {
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);

  useLayoutEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const rect = inputRef.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const topOfContainer = customStart
      ? rect.top + (rect.height * customStart) / 100 + scrollTop - before
      : rect.top + scrollTop - before;
    const BottomOfContainer = customEnd
      ? topOfContainer + (rect.height * customEnd) / 100 + after
      : topOfContainer + rect.height + after;
    setStart(topOfContainer / document.body.clientHeight);
    setEnd(BottomOfContainer / document.body.clientHeight);
  });

  return { start, end };
}

export default useRefScrollProgress;

import React, { CSSProperties, useState } from 'react';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import { graphql, PageProps } from 'gatsby';
import Intro from '@/components/Intro';
import AddToCart from '@/components/AddToCart';
import AboutMikafi from '@/components/AboutMikafi';
import CoffeeDetails from '@/components/CoffeeDetails';
import { FormattedMessage, useIntl } from 'react-intl';
import MikafiExperience from '@/components/MikafiExperience';
import Modal from '@/components/Modal';
import { Accordion, Button, Select, Tag, TasteNote } from '@mikafi/ui';
import Cart from '@/assets/icons/cart.svg';
import Close from '@/assets/icons/close.svg';
import { AnimatePresence, motion } from 'framer-motion';
import QuantitySelector from '@/components/QuantitySelector';
import { useEffect } from 'react';
import { renderRule, StructuredText } from 'react-datocms';
import Plus from '@/assets/icons/plus.svg';
import Dyor from '@/assets/icons/dyor.svg';
import ArrowDown from '@/assets/icons/arrow-down.svg';
import CoffeeInfo from '@/components/CoffeeInfo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { camalize } from '@/utils/string';
import { MOODS, NOTES, ROAST_LEVELS } from '../constants';
import classNames from 'classnames';
import Carousel from '@/components/Carousel';
import { isLink, isParagraph } from 'datocms-structured-text-utils';
import { defaultMetaTransformer } from 'datocms-structured-text-generic-html-renderer';

const compareRoastLevels = (a: string, b: string) => {
  return ROAST_LEVELS[camalize(a)] - ROAST_LEVELS[camalize(b)];
};

type PageContextProps = {
  id: string;
  locale: string;
};

type DataProps = {
  shopifyProduct: any;
  allDatoCmsFaq: any;
  datoCmsProduct: any;
  beanImages: any;
};

const Product = ({ data }: PageProps<DataProps, PageContextProps>) => {
  const shopifyProduct = data.shopifyProduct;
  const {
    options,
    variants,
    variants: [initialVariant],
  } = shopifyProduct;

  const intl = useIntl();
  const datoCmsProduct = data.datoCmsProduct || {};
  const creator = datoCmsProduct?.productCreator;
  const beans = datoCmsProduct?.beans;
  const beansImages = data?.beanImages?.nodes;
  const [bean] = beans || [];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [quantitySelector, setQuantitySelector] = useState<boolean>(false);
  const [variant, setVariant] = useState({ ...initialVariant });
  const [showTraceability, setShowTraceability] = useState<boolean>(false);

  const roastLevel = variant.selectedOptions?.find(
    (item: any) => item.name === 'Roast level',
  )?.value;

  const tasteNotes = variant.tasteNotes?.value
    ? JSON.parse(variant.tasteNotes.value)
    : {};

  useEffect(() => {
    if (quantitySelector) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [quantitySelector]);

  const faqs = data.allDatoCmsFaq.nodes;

  const handleOptionChange = (index: number, value: string) => {
    if (value === '') {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find((variant: any) => {
      return (
        JSON.stringify(currentOptions) ===
        JSON.stringify(
          variant ? variant.selectedOptions : initialVariant.selectedOptions,
        )
      );
    });

    if (selectedVariant) {
      setVariant({ ...selectedVariant });
    }
  };

  const roastLevelDescription = variant.roastLevelDescription?.value
    ? JSON.parse(variant.roastLevelDescription.value)
    : {};

  const roastLevelOptions = options.find(
    (item: any) => item.name === 'Roast level',
  );

  return (
    <Layout
      variant={creator ? 'light' : 'dark'}
      navChangeColor={creator ? true : false}
      alternatePages={[
        { locale: 'de', value: shopifyProduct.handle },
        { locale: 'en', value: shopifyProduct.handle },
      ]}
    >
      <Seo
        title={shopifyProduct.title}
        description={datoCmsProduct.description}
      />

      {creator ? (
        <>
          <section className="rounded-b-4xl relative overflow-hidden bg-white z-10">
            <Intro
              videoId={creator.video.providerUid}
              previewVideoId={creator.videoPreview.providerUid}
              videoThumbnail={
                <GatsbyImage
                  className="h-full w-full"
                  image={
                    creator.videoThumbnail?.childImageSharp?.gatsbyImageData
                  }
                  alt={shopifyProduct.title}
                />
              }
            />
          </section>
          <section className="rounded-b-4xl relative overflow-hidden bg-white -mt-10">
            <div className="py-16 md:py-20 px-8 md:px-24 container mx-auto">
              {/* Teaser */}
              <div className="md:flex md:space-x-12">
                <div className="md:w-3/12 pb-8 md:pb-0 flex md:flex-col justify-between md:justify-start mt-1">
                  <h3>Limited Edition</h3>
                  <div className="font-medium text-xl">
                    <h3>{`${shopifyProduct.stock?.value}/500`}</h3>
                  </div>
                </div>
                <div className="md:flex-1 max-w-3xl">
                  <h1>{shopifyProduct.title}</h1>
                  <h2 className="pt-2.5 pb-8 font-normal break-words text-2xl">
                    {creator?.intro}
                  </h2>
                  <p className="uppercase font-medium text-xl mb-4">
                    {intl.formatMessage({ id: 'from' })}{' '}
                    {new Intl.NumberFormat(`${intl.locale}-CH`, {
                      currency: 'CHF',
                      style: 'currency',
                    }).format(
                      shopifyProduct.priceRangeV2.minVariantPrice.amount,
                    )}
                  </p>
                  <Button
                    className="flex justify-center items-center px-20"
                    onClick={() => setQuantitySelector(true)}
                  >
                    <span className="inline-block">
                      <Cart className="fill-white mr-2 h-8 w-8" />
                    </span>
                    {intl.formatMessage({ id: 'buyCoffee' })}
                  </Button>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-noise pb-16 sm:pb-12 relative -mt-8 pt-16">
            <div className="px-8 md:px-24 container mx-auto pt-4 sm:py-4 md:py-16">
              <div className="md:flex md:space-x-12">
                <div className="md:w-3/12 mb-8">
                  <h3>{shopifyProduct.title}</h3>
                </div>
                <div className="md:flex-1">
                  <h2 className="mt-2 sm:mt-0 font-normal mb-6 text-2xl">
                    {datoCmsProduct.description}
                  </h2>

                  <div>
                    <div className="flex flex-wrap -m-1.5 ">
                      {datoCmsProduct.tags?.map(({ title, text }: any) => (
                        <div key={title} className="my-1.5 mx-1 sm:m-1.5">
                          <Tag
                            tag={title}
                            text={text}
                            bgColor={MOODS[shopifyProduct.mood?.value]?.bg}
                          />
                        </div>
                      ))}

                      {beans.length > 0 && (
                        <button
                          onClick={() => setIsOpen(true)}
                          className="m-1.5 flex items-center text-white w-fit bg-black rounded-full px-5 py-3 text-xs font-normal"
                        >
                          <Plus className="w-3 h-3 fill-white mr-2" />
                          <FormattedMessage id="moreAboutTheBeans" />
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="mt-4 lg:mt-16">
                    <div className="lg:flex md:space-x-4 justify-between">
                      {shopifyProduct?.featuredImage?.gatsbyImageData && (
                        <div className="lg:w-5/12 my-12 lg:my-0 lg:mr-12">
                          {shopifyProduct.featuredImage.gatsbyImageData && (
                            <GatsbyImage
                              image={
                                shopifyProduct.featuredImage.gatsbyImageData
                              }
                              alt={shopifyProduct.title}
                            />
                          )}
                        </div>
                      )}
                      <div className="lg:w-7/12">
                        <div className="flex mb-8 space-x-4 items-center">
                          <div className="label-small">
                            {intl.formatMessage({ id: 'roastLevel' })}
                          </div>
                          <div className="w-full max-w-xs">
                            {options.find(
                              (item: any) => item.name === 'Roast level',
                            )?.values.length > 1 ? (
                              <Select
                                value={
                                  variant.selectedOptions?.find(
                                    (item: any) => item.name === 'Roast level',
                                  )?.value
                                }
                                onChange={event =>
                                  handleOptionChange(0, event.target.value)
                                }
                                options={roastLevelOptions?.values
                                  ?.sort(compareRoastLevels)
                                  .map((value: any) => ({
                                    name: intl.formatMessage({
                                      id: `${camalize(value)}Roast`,
                                    }),
                                    value: value,
                                  }))}
                              />
                            ) : (
                              intl.formatMessage({
                                id: `${camalize(
                                  roastLevelOptions?.values?.[0],
                                )}Roast`,
                              }) || ''
                            )}
                          </div>
                        </div>

                        <p>{roastLevelDescription[intl.locale]}</p>

                        <hr className="my-8" />

                        <CoffeeInfo
                          tasteNotes={Object.keys(tasteNotes).map(item => ({
                            note: item,
                            value: tasteNotes[item],
                          }))}
                          acidity={variant.acidity?.value}
                          bitterness={variant.bitterness?.value}
                          body={variant.body?.value}
                          roastGrade={roastLevel || variant.roastLevel?.value}
                          mood={shopifyProduct.mood?.value}
                          suitedFor={
                            shopifyProduct.suitedFor &&
                            JSON.parse(shopifyProduct.suitedFor.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="bg-noise relative">
            <div className="container mx-auto pt-40 pb-4 px-4 md:px-32">
              <div className="md:flex gap-12">
                <div className="basis-1/3 flex-none">
                  {shopifyProduct?.featuredImage?.gatsbyImageData && (
                    <div className="max-w-xs mx-auto sm:ml-auto px-8 sm:px-0">
                      {shopifyProduct.featuredImage.gatsbyImageData && (
                        <GatsbyImage
                          image={shopifyProduct.featuredImage.gatsbyImageData}
                          alt={shopifyProduct.title}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="basis-2/3 flex-none pt-12 sm:pt-0 lg:pt-12">
                  <h1 className="text-4xl md:text-5xl">
                    {shopifyProduct.title}
                  </h1>
                  <p className="mb-0 text-2xl">{datoCmsProduct.description}</p>

                  <div className="flex flex-wrap -m-1.5 my-4">
                    {datoCmsProduct.tags?.map(({ title, text }: any) => (
                      <div key={title} className="my-1.5 mx-1 sm:m-1.5">
                        <Tag
                          tag={title}
                          text={text}
                          bgColor={MOODS[shopifyProduct.mood?.value]?.bg}
                        />
                      </div>
                    ))}
                  </div>

                  <button
                    onClick={() => {
                      const section = document.querySelector(`#dyor`);
                      section?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }}
                    className="flex items-center text-white w-fit bg-black rounded-full px-5 py-4 text-xs font-normal"
                  >
                    <Dyor className="w-5 h-5 mr-2" />
                    <FormattedMessage id="designYourOwnRoast" />
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-noise relative" id="dyor">
            <div className="container mx-auto px-4 py-12 md:p-12">
              <div className="bg-white px-4 py-6 md:p-8 rounded-2xl">
                <h3 className="mb-4">Design your own Roast</h3>

                <div className="md:flex">
                  <div className="md:basis-1/2 flex-none flex">
                    <div className="px-12 lg:px-24 py-4 md:py-12 max-w-lg mx-auto">
                      <div className="relative">
                        <GatsbyImage
                          className="bg-white"
                          imgClassName="bg-white"
                          image={
                            beansImages?.find(
                              (image: any) =>
                                image.name === camalize(roastLevel),
                            )?.childImageSharp?.gatsbyImageData
                          }
                          alt="Bean"
                        />

                        {Object.keys(tasteNotes).map((item, i) => {
                          const positions: { [key: number]: CSSProperties } = {
                            0: { top: '0%', right: '0%' },
                            1: { bottom: '30%', left: '0%' },
                            2: { right: '10%', bottom: 0 },
                          };

                          return (
                            <div
                              className="absolute"
                              style={positions[i]}
                              key={item}
                            >
                              <TasteNote
                                note={item}
                                size={tasteNotes[item] * 10}
                                style={{ backgroundColor: NOTES[item] }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="md:basis-1/2 flex-none">
                    <div className="mb-8">
                      <div className="label-small mb-4">
                        {intl.formatMessage({ id: 'chooseRoastGrade' })}
                      </div>
                      <div>
                        <div className="flex gap-4 md:flex-wrap">
                          {roastLevelOptions?.values
                            ?.sort(compareRoastLevels)
                            .map((value: any) => {
                              const selectedRoastLevel =
                                variant.selectedOptions?.find(
                                  (item: any) => item.name === 'Roast level',
                                )?.value;

                              return (
                                <button
                                  key={value}
                                  className={classNames(
                                    'py-3 px-5 border rounded-xl text-sm',
                                    {
                                      'bg-black text-white border-black':
                                        value === selectedRoastLevel,
                                      'border-gray-200':
                                        value !== selectedRoastLevel,
                                    },
                                  )}
                                  onClick={() => handleOptionChange(0, value)}
                                >
                                  {value}
                                </button>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div className="max-w-sm mb-12">
                      <p className="min-h-[72px] mb-6">
                        {roastLevelDescription[intl.locale]}
                      </p>

                      <CoffeeInfo
                        showMood={false}
                        showTasteNotes={false}
                        tasteNotes={Object.keys(tasteNotes).map(item => ({
                          note: item,
                          value: tasteNotes[item],
                        }))}
                        acidity={variant.acidity?.value}
                        bitterness={variant.bitterness?.value}
                        body={variant.body?.value}
                        roastGrade={roastLevel || variant.roastLevel?.value}
                        mood={shopifyProduct.mood?.value}
                        suitedFor={
                          shopifyProduct.suitedFor &&
                          JSON.parse(shopifyProduct.suitedFor.value)
                        }
                      />
                    </div>

                    <div className="font-medium text-base mb-4">
                      {intl.formatMessage({ id: 'from' })}{' '}
                      {new Intl.NumberFormat(`${intl.locale}-CH`, {
                        currency: 'CHF',
                        style: 'currency',
                      }).format(
                        shopifyProduct.priceRangeV2.minVariantPrice.amount,
                      )}
                    </div>

                    <Button
                      className="flex justify-center items-center px-20 py-3"
                      onClick={() => setQuantitySelector(true)}
                    >
                      <span className="inline-block">
                        <Cart className="fill-white mr-2 h-8 w-8" />
                      </span>
                      {intl.formatMessage({ id: 'roastCoffee' })}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {bean && (
            <section className="bg-white relative">
              <div className="container mx-auto px-4 py-12 md:py-24 md:p-12">
                <div className="md:flex">
                  <div className="md:basis-3/12 mb-4 md:mb-0">
                    <h3>{intl.formatMessage({ id: 'transparency' })}</h3>
                  </div>
                  <div className="flex-1">
                    <p className="mt-0 text-lg md:text-xl">
                      {intl.formatMessage({ id: 'seekingQuality' })}
                    </p>

                    <div className="bg-gray-100 rounded-4xl">
                      <div className="px-4 py-12 md:px-12 md:py-8 grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
                        {bean?.traceability?.map((item: any, i: number) => (
                          <div
                            className={classNames('lg:mr-12', {
                              block: i > 1 && showTraceability,
                              'hidden md:block': i > 1 && !showTraceability,
                            })}
                            key={item.title}
                          >
                            <div className="truncate w-full">{item.title}</div>
                            <div className="text-2xl font-medium">
                              {Intl.NumberFormat('de-CH', {
                                style: 'currency',
                                currency: 'CHF',
                              }).format(item.price)}
                            </div>
                            <div className="text-xs">{item.summary}</div>
                          </div>
                        ))}
                      </div>

                      <div className="border-t border-gray-300 relative">
                        <button
                          className="md:hidden leading-none flex justify-center items-center text-[10px] rounded-full absolute left-1/2 -translate-x-1/2 -top-6 py-5 px-6 bg-gray-100 border-gray-300 border"
                          onClick={() => setShowTraceability(!showTraceability)}
                        >
                          <span>
                            {showTraceability ? 'Show less' : 'Show more'}
                          </span>

                          <ArrowDown
                            className={classNames('w-3 h-3 ml-4', {
                              'rotate-180': showTraceability,
                            })}
                          />
                        </button>
                        <div className="px-4 py-8 md:px-12">
                          <div>Total</div>
                          <div>
                            <span className="text-3xl font-medium">
                              {Intl.NumberFormat('de-CH', {
                                style: 'currency',
                                currency: 'CHF',
                              }).format(
                                bean.traceability.reduce(
                                  (previousValue: number, currentValue: any) =>
                                    previousValue + currentValue.price,
                                  0,
                                ),
                              )}
                            </span>{' '}
                            / 330g
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {bean?.stories.length > 0 && (
            <section className="bg-white relative">
              <div className="container mx-auto px-4 py-12 md:p-12">
                <h3 className="mb-8">{intl.formatMessage({ id: 'story' })}</h3>
                <Carousel
                  className="-mx-4 md:mx-0"
                  controlClassName="md:absolute md:pl-20 md:left-1/2 md:right-0 md:bottom-8 mx-4 md:mx-0"
                >
                  {bean?.stories.map((item: any, i: number) => {
                    return (
                      <div key={item.id} className="px-4 md:pl-0 md:pr-8">
                        <div className="md:flex md:gap-24">
                          <div className="md:basis-6/12">
                            {bean.image ? (
                              <div className="mb-8">
                                <GatsbyImage
                                  objectFit="contain"
                                  image={item.image.gatsbyImageData}
                                  className="w-full rounded-xl"
                                  alt={''}
                                  imgClassName="rounded-xl"
                                />
                              </div>
                            ) : null}
                          </div>
                          <div className="flex-1 md:pb-16 text-xs md:text-lg md:-mt-4">
                            <StructuredText
                              data={item.text?.value}
                              customNodeRules={[
                                renderRule(
                                  isParagraph,
                                  ({
                                    adapter: { renderNode },
                                    key,
                                    children,
                                  }) => {
                                    return renderNode(
                                      'p',
                                      { key, className: 'mb-4' },
                                      children,
                                    );
                                  },
                                ),
                                renderRule(
                                  isLink,
                                  ({
                                    adapter: { renderNode },
                                    key,
                                    children,
                                    node,
                                  }) => {
                                    const meta = node.meta
                                      ? defaultMetaTransformer({
                                          node,
                                          meta: node.meta,
                                        })
                                      : {};

                                    return renderNode(
                                      'a',
                                      {
                                        ...(meta || {}),
                                        key,
                                        href: node.url,
                                        className:
                                          'bg-black text-white no-underline py-4 px-6 rounded-full text-xs inline-block',
                                      },
                                      children,
                                    );
                                  },
                                ),
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </section>
          )}
        </>
      )}
      <div className="bg-white">
        <section
          className={classNames(
            'relative overflow-hidden bg-opacity-50',
            MOODS[shopifyProduct.mood?.value]?.bg,
          )}
        >
          <div className="px-4 md:px-24 container mx-auto">
            {shopifyProduct.featuredImage?.gatsbyImageData && (
              <MikafiExperience
                packagingImage={shopifyProduct.featuredImage.gatsbyImageData}
              />
            )}
          </div>
        </section>
      </div>

      <section className="bg-mikafi">
        <div className="pt-12 md:pt-20 mx-6 md:mx-8 lg:mx-16 max-w-screen-2xl 2xl:mx-auto 2xl:px-16 pb-10 sm:pb-20">
          <h3 className="mb-10">{intl.formatMessage({ id: 'aboutMikafi' })}</h3>
          <AboutMikafi />

          <div className="md:flex md:space-x-12 mt-20 sm:mt-24">
            <div className="md:w-4/12">
              <h3 className="mt-8 mb-8 md:mb-0 md:mt-12">
                {intl.formatMessage({ id: 'ourPromise' })}
              </h3>
            </div>
            <div className="flex-1">
              {faqs?.map((item: any) => (
                <Accordion
                  key={item.id}
                  title={item.title}
                  text={<StructuredText data={item.text} />}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      <AddToCart
        product={shopifyProduct}
        variant={variant}
        setVariant={setVariant}
      />

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        title={intl.formatMessage({ id: 'coffeeDetails' })}
        variant="dark"
      >
        <div className="p-8">
          <CoffeeDetails beans={beans} />
        </div>
      </Modal>

      <AnimatePresence>
        {quantitySelector && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.4 }}
              exit={{ opacity: 0 }}
              transition={{ ease: [0.45, 0, 0.55, 1], duration: 0.3 }}
              className="z-30 fixed inset-0 bg-black opacity-40"
              onClick={() => setQuantitySelector(false)}
            />
            <motion.div
              initial={{ opacity: 0.9, x: 1000 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0.9, x: 1000 }}
              transition={{
                ease: [0.45, 0, 0.55, 1],
                duration: 0.3,
              }}
              className="z-30 fixed inset-4 md:right-4 md:top-4 md:bottom-4 p-6 bg-white md:left-auto md:w-full max-w-md flex flex-col overflow-auto rounded-4xl"
            >
              <div className="flex flex-row justify-between items-center mb-8">
                <h4>{shopifyProduct?.title}</h4>

                <button onClick={() => setQuantitySelector(false)}>
                  <Close className="h-12 w-12" />
                </button>
              </div>
              <QuantitySelector
                product={shopifyProduct}
                variant={variant}
                setVariant={setVariant}
                callback={() => setQuantitySelector(false)}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </Layout>
  );
};

export default Product;

export const query = graphql`
  query ProductQuery($shopifyId: String, $locale: String) {
    beanImages: allFile(filter: { relativePath: { glob: "images/beans/*" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    datoCmsProduct(productId: { eq: $shopifyId }, locale: { eq: $locale }) {
      description
      tags {
        title
        text
      }
      productCreator {
        firstName
        lastName
        intro
        image {
          gatsbyImageData
        }
        videoThumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
        video {
          providerUid
        }
        videoPreview {
          providerUid
        }
      }
      beans {
        stories {
          id
          image {
            gatsbyImageData
          }
          text {
            value
          }
        }
        country
        location
        name
        estate
        image {
          gatsbyImageData
        }
        altitude
        description
        species
        variety
        preparation
        harvestTime
        certificates
        traceability {
          title
          price
          summary
        }
      }
    }
    shopifyProduct(shopifyId: { eq: $shopifyId }) {
      id
      storefrontId
      handle
      title
      description
      featuredImage {
        gatsbyImageData
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
        acidity: metafield(key: "acidity", namespace: "specs") {
          namespace
          value
        }
        bitterness: metafield(key: "bitterness", namespace: "specs") {
          namespace
          value
        }
        body: metafield(key: "body", namespace: "specs") {
          namespace
          value
        }
        tasteNotes: metafield(key: "taste_notes", namespace: "specs") {
          namespace
          value
        }
        roastLevelDescription: metafield(
          key: "roastLevelDescription"
          namespace: "specs"
        ) {
          namespace
          value
        }
      }
      options {
        name
        values
        id: shopifyId
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      suitedFor: metafield(key: "suitedFor", namespace: "specs") {
        value
      }
      stock: metafield(key: "stock", namespace: "product") {
        namespace
        value
      }
      mood: metafield(key: "mood", namespace: "specs") {
        namespace
        value
      }
    }
    allDatoCmsFaq(filter: { locale: { eq: $locale } }) {
      nodes {
        id
        title
        position
        text {
          value
        }
      }
    }
  }
`;

import React, { FC, useEffect, useRef, useState } from 'react';
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from 'gatsby-plugin-image';
import useRefScrollProgress from '@/hooks/useRefScrollProgress';
import { useScroll, useTransform, motion } from 'framer-motion';
import { useIntl } from 'react-intl';

interface Props {
  packagingImage: IGatsbyImageData;
}

const MikafiExperience: FC<Props> = packagingImage => {
  const intl = useIntl();

  //Parrallax anim of bubble
  const scrollAnimRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [scrollAnimParrallax, setScrollAnimParrallax] = useState(0);
  const { scrollYProgress } = useScroll();

  const animMargin = () => {
    if (typeof window !== 'undefined') {
      return window.innerHeight;
    }
  };

  const parrallax = useTransform(
    scrollYProgress,
    [
      useRefScrollProgress(scrollAnimRef, 0, 100, animMargin(), animMargin())
        .start,
      useRefScrollProgress(scrollAnimRef, 0, 100, animMargin(), animMargin())
        .end,
    ],
    [20, -20],
  );

  useEffect(() => {
    const onScroll = () => {
      setScrollAnimParrallax(parrallax.get());
    };

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      ref={scrollAnimRef}
      className="relative text-center flex flex-col justify-center items-center pt-24 md:pt-32 pb-32 md:pb-28 max-w-screen-2xl 2xl:mx-auto"
    >
      <motion.div
        className="absolute -top-[8rem] -right-[3rem] lg:-top-[10rem] lg:-right-[5rem]"
        style={{
          y: scrollAnimParrallax * -8,
          rotate: '10deg',
        }}
      >
        {packagingImage && (
          <GatsbyImage
            image={packagingImage.packagingImage}
            alt=""
            objectFit="contain"
            className="w-[40vw] sm:w-[30vw] lg:w-96 aspect-[4/3]"
          />
        )}
      </motion.div>
      <motion.div
        className="absolute -top-[5rem] -left-[2rem] lg:top-[10rem] lg:-left-[10rem] overflow-hidden rounded-4xl"
        style={{
          y: scrollAnimParrallax * -3,
          rotate: '-10deg',
        }}
      >
        <StaticImage
          src="./../../assets/images/roaster.png"
          alt=""
          objectFit="cover"
          className="w-[50vw] sm:w-[25vw] lg:w-[26rem] aspect-[4/3]"
        />
      </motion.div>
      <motion.div
        className="absolute bottom-[2rem] right-[5rem] md:-bottom-0 md:-right-[5rem] lg:-bottom-[2rem] lg:-right-[5rem] overflow-hidden rounded-4xl"
        style={{
          y: scrollAnimParrallax * -5,
          rotate: '-10deg',
        }}
      >
        <StaticImage
          src="./../../assets/images/farmer.jpg"
          alt=""
          objectFit="cover"
          className=" w-[55vw] sm:w-[30vw] lg:w-96 aspect-[4/3]"
        />
      </motion.div>
      <motion.div
        className="space-y-4 md:space-y-16 relative"
        style={{
          y: scrollAnimParrallax * 1.5,
        }}
      >
        <h3>Mikafi Experience</h3>
        <p className="text-4xl sm:text-5xl md:text-7xl md:max-w-1/2	font-normal sm:max-w-4xl whitespace-pre-line">
          {intl.formatMessage({ id: 'orderText' })}
        </p>
      </motion.div>
    </div>
  );
};

export default MikafiExperience;

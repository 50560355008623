import React, { FC, ReactElement, ReactNode, useRef, useState } from 'react';
import Slider from 'react-slick';

import '../../styles/slick.css';

interface Props {
  children?: ReactNode;
  className?: string;
  controlClassName?: string;
  arrows?: boolean;
  dots?: boolean;
}

const Carousel: FC<Props> = ({
  children,
  className,
  controlClassName,
  arrows = true,
  dots = false,
}) => {
  const ref = useRef<Slider>(null);
  const [current, setCurrent] = useState<number | null>(1);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots,
    arrows: false,
    afterChange: (current: number) => setCurrent(current + 1),
    dotsClass: 'list-none flex space-x-2 justify-center my-6 mx-0 px-0',
    appendDots: (dots: any) => (
      <ul>
        {dots.map((item: ReactElement) => {
          const isActive = item.props.className === 'slick-active';

          const clonedItem = {
            ...item,
            props: {
              ...item.props,
              children: {
                ...item.props.children,
                props: {
                  ...item.props.children.props,
                  className: 'w-2 h-2',
                },
              },
            },
          };

          return React.cloneElement(clonedItem, {
            className: `w-2 h-2 rounded-full bg-black overflow-hidden text-[0px] ${
              isActive ? 'bg-opacity-100' : 'bg-opacity-[0.15]'
            }`,
          });
        })}
      </ul>
    ),
  };

  const onClickNext = () => {
    ref!.current!.slickNext();
  };

  const onClickPrev = () => {
    ref!.current!.slickPrev();
  };

  return (
    <div className={`relative ${className}`}>
      <Slider ref={ref} {...settings}>
        {children}
      </Slider>

      {arrows && (
        <div
          className={`flex items-center justify-between ${controlClassName}`}
        >
          <button
            onClick={onClickPrev}
            className="h-10 w-10 flex items-center justify-center border border-gray-200 rounded-full bg-white"
            aria-label="Previous"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[6px] h-[10px] fill-transparent stroke-black"
            >
              <path d="M5.035.964.999 5l4.036 4.036" />
            </svg>
          </button>
          <span className="text-lg">
            {current} / {React.Children.count(children)}
          </span>
          <button
            onClick={onClickNext}
            className="h-10 w-10 flex items-center justify-center border border-gray-200 rounded-full bg-white"
            aria-label="Next"
          >
            <svg
              className="w-[6px] h-[10px] fill-transparent stroke-black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.036.964 5.07 5 1.036 9.036" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
